import $ from "jquery"
global.$ = $
global.jQuery = $

import "./numbers"

import bsCustomFileInput from 'bs-custom-file-input'

export default {
  start: () => {
    document.addEventListener("turbolinks:load", () => {
      initComponents()
      applyMasks()
      focusOnFirstInvalidField()
      addNavbarShadowOnScroll()
    })
    document.addEventListener("turbolinks:before-cache", () => {
      resetComponents()
    })
  },
}

const initComponents = () => {
  $.fn.tooltip.Constructor.Default.delay = {show: 600}
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('[data-visitable="true"]').visitable()
  bsCustomFileInput.init()
}

const focusOnFirstInvalidField = () => {
  // Coloca o foco no primeiro campo inválido após uma validação no servidor
  const firstInvalidField = document.querySelector(".is-invalid")
  if (firstInvalidField !== null) firstInvalidField.focus()
}

const addNavbarShadowOnScroll = () => {
  const offset = 10
  const shadow = "navbar-shadow"
  const navbar = document.getElementById("navbar")

  window.onscroll = () => {
    if (document.body.scrollTop > offset || document.documentElement.scrollTop > offset) {
      navbar.classList.add(shadow)
    } else {
      navbar.classList.remove(shadow)
    }
  }
}

const applyMasks = () => {
  // https://github.com/igorescobar/jQuery-Mask-Plugin
  $.applyDataMask()

  $("input.tel").mask(maskBehavior, options)
}

const resetComponents = () => {
  $('[data-toggle="tooltip"]').tooltip("hide")
  $(".navbar-collapse.show").removeClass("show")
  $(".highlight").removeClass("highlight")
  $(".flash").hide()
}

// http://www.igorescobar.com/blog/2012/07/29/mascara-javascript-para-os-novos-telefones-de-sao-paulo/
const maskBehavior = function (val) {
    return val.replace(/\D/g, "").length === 11 ? "00 00000-0000" : "00 0000-00009"
  },
  options = {
    onKeyPress: function (val, e, field, options) {
      field.mask(maskBehavior.apply({}, arguments), options)
    }
  }

$.fn.visitable = function () {
  this.click(function (event) {
    // só dispara o evento caso ele não tenha sido
    // acionado por algum filho de um elemento <a>
    if ($(event.target).parents("a").length === 0) {
      Turbolinks.visit(this.dataset.location)
    }
  })
}
