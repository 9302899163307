import { Controller } from "stimulus"

const colours = [
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#3B3EAC",
  "#0099C6",
  "#DD4477",
  "#66AA00",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#329262",
  "#5574A6",
  "#651067"
]

export default class extends Controller {
  connect() {
    const name = this.data.get("name") || ''
    let size = this.data.get("size") || 64

    const nameSplit = String(name).toUpperCase().split(" ")
    let initials = nameSplit[0] ? nameSplit[0].charAt(0) : "?"

    if (nameSplit.length > 1) {
      const lastName = nameSplit[nameSplit.length - 1]
      initials = initials + lastName.charAt(0)
    }

    if (window.devicePixelRatio) {
      size = (size * window.devicePixelRatio)
    }

    const charIndex = (initials === "?" ? 72 : initials.charCodeAt(0)) - 64
    const colourIndex = charIndex % colours.length

    const canvas = document.createElement("canvas")
    canvas.width = size
    canvas.height = size

    const context = canvas.getContext("2d")
    context.fillStyle = colours[colourIndex - 1]
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.font = Math.round(canvas.width / 2) + "px Arial"
    context.textAlign = "center"
    context.fillStyle = "#fff"
    context.fillText(initials, size / 2, size / 1.5)

    const dataURI = canvas.toDataURL()

    const img = this.element
    img.setAttribute("src", dataURI)
  }
}
