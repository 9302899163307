/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param n integer: length of decimal
 * @param x integer: length of whole part
 * @param s mixed: sections delimiter
 * @param c mixed: decimal delimiter
 *
 * @link https://stackoverflow.com/a/14428340/2640073
 */
Number.prototype.format = function(n, x, s, c) {
  // personalização da solução original para que o separador de milhar
  // e o delimitador decimal padrões sejam . e , respectivamente
  s = s ? s : '.'
  c = c ? c : ','

  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = this.toFixed(Math.max(0, ~~n))

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))
}
