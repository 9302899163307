import { Controller } from "stimulus"

import dayjs from "dayjs"

export default class extends Controller {
  connect() {
    this.configureDayJS()
    this.buildLineChart()
  }

  buildLineChart() {
    const ctx = this.canvas.getContext("2d")

    new Chart(ctx, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [{
          data: this.values,
          fill: false,
          borderColor: "#3366CC",
          backgroundColor: "#3366CC",
          label: "Ações de venda realizadas"
        }]
      },
      options: {
        responsive: true,
        legend: false,
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            title: (tooltipItems, data) => {
              const index = tooltipItems[0].index
              return dayjs(data.labels[index]).format("ddd, DD [de] MMM [de] YYYY")
            }
          }
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: false,
            scaleLabel: {
              display: false,
              labelString: "Dia"
            },
            ticks: {
              padding: 10,
              callback: (date) => {
                return dayjs(date).format("DD")
              }
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: false,
              labelString: "Ações de venda realizadas"
            },
            ticks: {
              padding: 5,
              suggestedMax: 10,
              beginAtZero: true
            }
          }]
        },
        maintainAspectRatio: false
      },
    })
  }

  get canvas() {
    return this.element
  }

  get series() {
    return JSON.parse(this.canvas.dataset.series)
  }

  get labels() {
    return Object.keys(this.series)
  }

  get values() {
    return Object.values(this.series)
  }

  configureDayJS() {
    require('dayjs/locale/pt-br')
    dayjs.locale('pt-br')
  }
}
