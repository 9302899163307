import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.buildPieChart()
  }

  buildPieChart() {
    const ctx = this.canvas.getContext("2d")

    const labels = this.labels
    const values = this.values
    const colors = this.colors

    const total = values.reduce((total, currentValue) => total + currentValue)

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: colors,
          label: "Ações de venda realizadas"
        }]
      },
      options: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const index = tooltipItem.index
              const label = data.labels[tooltipItem.index]
              const count = data.datasets[0].data[index]
              const percent = this.percent(count, total)
              return `${label}: ${count} (${percent})`
            }
          }
        }
      }
    })
  }

  get canvas() {
    return this.element
  }

  get count() {
    return JSON.parse(this.canvas.dataset.count)
  }

  get labels() {
    return Object.keys(this.count)
  }

  get values() {
    return Object.values(this.count)
  }

  get colors() {
    const colorsMap = JSON.parse(this.canvas.dataset.colors)
    return Object.values(colorsMap).map(colorName => {
      return getComputedStyle(document.documentElement).getPropertyValue(`--${colorName}`).trim()
    })
  }

  percent(value, total) {
    if (total != 0) {
      return ((value / total) * 100).format(0) + "%"
    }
    return "0%"
  }
}
