import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [ "allDayCheckbox", "datetimeInput" ]

  connect() {
    this.toggleDatetime()
  }

  toggleDatetime() {
    if (this.allDayCheckbox.checked) {
      this.changeInputTypeToDate()
    } else {
      this.changeInputTypeToDatetime()
    }
  }

  changeInputTypeToDate() {
    if (this.datetimeInput.type == "date") return

    this.storeDatetimeParts()
    this.datetimeInput.value = ""
    this.datetimeInput.type = "date"

    const previousDate = this.datetimeInput.dataset.previousDate
    if (previousDate) this.datetimeInput.value = previousDate
  }

  changeInputTypeToDatetime() {
    if (this.datetimeInput.type == "datetime-local") return

    this.storeDatetimeParts()
    this.datetimeInput.value = ""
    this.datetimeInput.type = "datetime-local"

    const previousDate = this.datetimeInput.dataset.previousDate
    const previousTime = this.datetimeInput.dataset.previousTime

    if (previousDate) this.datetimeInput.value = `${previousDate}T${previousTime || "00:00"}`
  }

  storeDatetimeParts() {
    const datetimeParts = this.datetimeInput.value.split("T")
    if (datetimeParts[0] != undefined) this.datetimeInput.dataset.previousDate = datetimeParts[0]
    if (datetimeParts[1] != undefined) this.datetimeInput.dataset.previousTime = datetimeParts[1]
  }

  get allDayCheckbox() {
    return this.allDayCheckboxTarget
  }

  get datetimeInput() {
    return this.datetimeInputTarget
  }
}
