import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "nameLabel", "documentLabel", "document", "kind" ]

  connect() {
    this.toggle()
    this.appendOnChangeKind()
  }

  toggle() {
    if (this.kind == "person") {
      this.applyPersonMaskToDocument()
      this.changeNameLabelToPerson()
      this.changeDocumentLabelToPerson()
    } else if (this.kind == "company") {
      this.applyCompanyMaskToDocument()
      this.changeNameLabelToCompany()
      this.changeDocumentLabelToCompany()
    }
  }

  applyPersonMaskToDocument() {
    $(this.documentTarget).mask("000.000.000-00")
  }

  applyCompanyMaskToDocument() {
    $(this.documentTarget).mask("00.000.000/0000-00")
  }

  changeNameLabelToPerson() {
    if (this.hasNameLabelTarget && this.nameLabelTarget.dataset.personLabel) {
      const personLabel = this.nameLabelTarget.dataset.personLabel
      this.changeLabelText(this.nameLabelTarget, personLabel)
    }
  }

  changeNameLabelToCompany() {
    if (this.hasNameLabelTarget && this.nameLabelTarget.dataset.companyLabel) {
      const companyLabel = this.nameLabelTarget.dataset.companyLabel
      this.changeLabelText(this.nameLabelTarget, companyLabel)
    }
  }

  changeDocumentLabelToPerson() {
    if (this.hasDocumentLabelTarget && this.documentLabelTarget.dataset.personLabel) {
      const personLabel = this.documentLabelTarget.dataset.personLabel
      this.changeLabelText(this.documentLabelTarget, personLabel)
    }
  }

  changeDocumentLabelToCompany() {
    if (this.hasDocumentLabelTarget && this.documentLabelTarget.dataset.companyLabel) {
      const companyLabel = this.documentLabelTarget.dataset.companyLabel
      this.changeLabelText(this.documentLabelTarget, companyLabel)
    }
  }

  changeLabelText(label, text) {
    const originalLabel = label.innerText.trim()
    label.innerHTML = label.innerHTML.replace(originalLabel, text)
  }

  appendOnChangeKind() {
    if (this.kindTarget.type == 'radio') {
      this.appendOnChangeKindRadio()
    }
  }

  appendOnChangeKindRadio() {
    for(const radio of this.kindTargets) {
      radio.addEventListener("change", this.toggle.bind(this))
    }
  }

  get kind() {
    if (this.kindTarget.type == 'radio') {
      return this.getKindValueFromRadio()
    }
  }

  getKindValueFromRadio() {
    for(const radio of this.kindTargets) {
      if (radio.checked) return radio.value
    }
  }
}
