import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "pictureInput" ]

  changePicture(event) {
    this.displayPictureInput()
    this.focusOnPictureInput()
    this.clickOnPictureInput()
    event.preventDefault()
  }

  displayPictureInput() {
    this.pictureInput.style.display = "block"
  }

  focusOnPictureInput() {
    this.pictureInput.querySelector("input").focus()
  }

  clickOnPictureInput() {
    this.pictureInput.querySelector("input").click()
  }

  get pictureInput() {
    return this.pictureInputTarget
  }
}
