import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "businessRoleSelect", "actAsConsultantCheckbox", "avatarInput" ]
  static values = { businessRoleConsultant: String }

  connect() {
    this.businessRoleChanged()
  }

  businessRoleChanged() {
    const actAsConsultantWrapper = this.actAsConsultantCheckbox.parentNode.parentNode
    const actAsConsultantHidden = this.actAsConsultantCheckbox.previousSibling
    const actAsConsultantLabel = this.actAsConsultantCheckbox.nextSibling

    if (this.businessRoleSelect.value == this.businessRoleConsultantValue) {
      this.actAsConsultantCheckbox.dataset.previouslyChecked = this.actAsConsultantCheckbox.checked

      this.actAsConsultantCheckbox.checked = true
      this.actAsConsultantCheckbox.disabled = true
      this.actAsConsultantCheckbox.classList.add("disabled")

      actAsConsultantHidden.disabled = true
      actAsConsultantLabel.classList.add("disabled")
      actAsConsultantWrapper.classList.add("disabled")
    } else {
      if (this.actAsConsultantCheckbox.dataset.previouslyChecked == "true") {
        this.actAsConsultantCheckbox.checked = true
      } else if (this.actAsConsultantCheckbox.dataset.previouslyChecked == "false") {
        this.actAsConsultantCheckbox.checked = false
      }

      this.actAsConsultantCheckbox.disabled = false
      this.actAsConsultantCheckbox.classList.remove("disabled")

      actAsConsultantHidden.disabled = false
      actAsConsultantLabel.classList.remove("disabled")
      actAsConsultantWrapper.classList.remove("disabled")
    }
  }

  changeAvatar(event) {
    this.displayAvatarInput()
    this.focusOnAvatarInput()
    this.clickOnAvatarInput()
    event.preventDefault()
  }

  displayAvatarInput() {
    this.avatarInput.style.display = "block"
  }

  focusOnAvatarInput() {
    this.avatarInput.querySelector("input").focus()
  }

  clickOnAvatarInput() {
    this.avatarInput.querySelector("input").click()
  }

  get businessRoleSelect() {
    return this.businessRoleSelectTarget
  }

  get actAsConsultantCheckbox() {
    return this.actAsConsultantCheckboxTarget
  }

  get avatarInput() {
    return this.avatarInputTarget
  }
}
